<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Admin - Manage Surface Types</h1>
          <v-row>
            <v-col class="col-12 col-md-4">
              <v-text-field
                label="Search"
                v-model="searchTerm"
                clearable />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-btn
                @click="addSurfaceType = true"
                small
                class="float-right">
                Add SurfaceType
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 col-md-8">
              <v-simple-table
                fixed-header
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Surface Type</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(type, fIndex) in filteredSurfaceTypes"
                      :key="fIndex"
                    >
                      <td>{{ type.surface_type }}</td>
                      <td>
                        <v-btn small @click="archive(fIndex)">
                          Archive
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="modal"
        v-if="addSurfaceType">
        <v-icon
          class="close"
          @click="addSurfaceType = false"
        >mdi-close-circle</v-icon>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newSurfaceType"
                label="Surface Type" />
            </v-col>
            <v-col class="pt-8">
              <v-btn
                @click="saveNewSurfaceType" small>Save Surface Type</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminSurfaceTypesIndex',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        surfaceTypes: [],
        filteredSurfaceTypes: [],
        searchTerm: null,
        addSurfaceType: false,
        newSurfaceType: null,
      };
    },
    watch: {
      searchTerm() {
        this.filterSurfaceTypes();
      },
    },
    methods: {
      archive(index) {
        const surfaceType = this.filteredSurfaceTypes[index];
        axios.get(`/surfaceTypes/archive/${surfaceType.id}.json?token=${this.token}`)
          .then((response) => {
            this.surfaceTypes = response.data.surfaceTypes;
            this.filteredSurfaceTypes = response.data.surfaceTypes;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      saveNewSurfaceType() {
        const postData = {};
        postData.surfaceType = this.newSurfaceType;
        axios.post(`/surfaceTypes/create/.json?token=${this.token}`, postData)
          .then((response) => {
            this.surfaceTypes = response.data.surfaceTypes;
            this.filteredSurfaceTypes = response.data.surfaceTypes;
            this.addSurfaceType = false;
            this.newSurfaceType = null;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      filterSurfaceTypes() {
        this.filteredSurfaceTypes = this.surfaceTypes;
        if (this.searchTerm) {
          this.filteredSurfaceTypes = this.surfaceTypes.filter((type) => type.surface_type.toLowerCase().includes(
            this.searchTerm.toLowerCase(),
          ));
        }
      },
      getSurfaceTypes() {
        axios.get(`/surfaceTypes/getAll.json?token=${this.token}&term=${this.searchTerm}`)
          .then((response) => {
            this.filteredSurfaceTypes = response.data;
            this.surfaceTypes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getSurfaceTypes();
    },
  };
  </script>
  